import Company from "@/models/Company"

export class Organization {
  coc: string
  code: string
  comments: string
  commercial: string
  company: Company
  createdTime: Date
  deleted: boolean
  extraCommunity: boolean
  id: string
  internalName: string
  intraCommunity: boolean
  language: string
  name: string
  network: any
  organizationType: any
  pms: string
  pw: string
  size: number
  subscription: string
  updatedTime: Date
  vat: string
  via: string
  website: string
}
