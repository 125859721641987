export enum OrganizationType {
  EQ = "EQ",
  SA = "SA",
  HB = "HB",
  OT = "OT",
}

export const organizationTypesArray = [
  OrganizationType.EQ,
  OrganizationType.SA,
  OrganizationType.HB,
  OrganizationType.OT,
]
